import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppId, RouteResolverService, } from '@skykick/core';

import { Utils } from '../../../services/utils';
import { UsersService } from '../../users/services/users.service';
import { PartnerInfoModel, PartnerInfoResponse } from '../models/PartnerInfoSave';
import { PartnerPassword } from '../models/PartnerPassword';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  

  private basePath = `${this.routeResolverService.generatePipeRoute(AppId.Papi)}`;

  constructor(
    private http: HttpClient, 
    private routeResolverService: RouteResolverService) { 

    }

  getInfo(): Observable<PartnerInfoResponse> {
    return this.http.get<PartnerInfoResponse>(
      `${this.basePath}webApi/cms/profile/get`)
      .pipe(
          catchError(error => Utils.handleError(error, UsersService.name))
      );
  }


  saveUserInfo(partnerInfoModel: PartnerInfoModel): Observable<PartnerInfoModel> {
    let pipeRoute: string = `${this.basePath}webApi/cms/profile/update`
    let returnObj: Observable<PartnerInfoModel> = this.http.post<PartnerInfoModel>(pipeRoute, partnerInfoModel)
    returnObj.pipe(catchError(error => Utils.handleError(error, UsersService.name)));
    return returnObj
  }

  changePassword(partnerPassword: PartnerPassword): Observable<any> {
    let pipeRoute: string = `${this.basePath}webApi/cms/profile/changepassword`
    let returnObj: Observable<any> = this.http.post<any>(pipeRoute, partnerPassword)
    returnObj.pipe(catchError(error => Utils.handleError(error, UsersService.name)));
    return returnObj
  }

  getUserIds(userName: string): Observable<boolean> {
    return this.http.get<boolean>(
            `${this.basePath}/exists?userName=${encodeURIComponent(userName)}`)
        .pipe(
            catchError(error => Utils.handleError(error, UsersService.name))
        );
  }

}
